@use "../../scss/variables.scss" as var;

.calculator__input-custom {
  $curved-border: 5px;

  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  border: 2px solid var.$clr-grey-light;
  border-radius: $curved-border;
  background: var.$clr-grey-light;
  text-align: right;
  padding: 0 0.75em;

  &--selected {
    border: 2px solid var.$clr-cyan-400;
  }

  &::placeholder {
    text-align: right;
    font-size: 1.5rem;
  }

  &:hover {
    border: 2px solid var.$clr-cyan-400;
    cursor: pointer;
  }

  &:focus {
    border: 2px solid var.$clr-cyan-400;
    outline: 0;
    cursor: text;

    &::placeholder {
      color: var.$clr-grey-light;
    }
  }
}

@media (min-width: var.$breakpoint-desktop) {
  .calculator__input-custom {
    // padding: 0;
    text-indent: 0.2em;

    &::placeholder {
      text-align: center;
    }

    &:placeholder-shown {
      padding: 0;
    }

    &:focus {
      padding: 0 0.75em;
    }
  }
}
