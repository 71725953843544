@use "../../scss/variables.scss" as var;

.calculator__btn-group-label {
  display: block;
  color: var.$clr-cyan-500;
  padding: 0 0 1em 0;
}

.calculator__btn-group {
  display: grid;
  grid-template-columns: repeat(2, 0.5fr);
  grid-template-rows: repeat(3, 0.33fr);
  gap: 1em;

  &-label {
    font-size: 1rem;
  }
}

@media (min-width: var.$breakpoint-desktop) {
  .calculator__btn-group {
    grid-template-columns: repeat(3, 0.33fr);
    grid-template-rows: repeat(2, 0.5fr);
  }
}
