@use "../../scss/variables.scss" as var;

.calculator {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  $curved-border: 25px;
  background-color: white;
  border-radius: $curved-border $curved-border 0 0;
  padding: 2em;
  box-shadow: 0px 32px 43px rgba(79, 166, 175, 0.200735);
}

@media (min-width: var.$breakpoint-tablet) {
  .calculator {
    $curved-border: 25px;
    border-radius: $curved-border;
    max-height: 50rem;
    max-width: 30rem;
  }
}

@media (min-width: var.$breakpoint-desktop) {
  .calculator {
    flex-grow: unset;
    flex-direction: row;
    gap: 2em;
    padding: 2em 2em 2em 3em;
    min-width: 57.5rem;
    min-height: 30rem;
    margin: 0 0 7.5em 0;
  }
}
