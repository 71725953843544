@use "scss/variables" as var;
@use "scss/global";

#root {
  display: flex;
  flex-direction: column;
}

@media (min-width: var.$breakpoint-tablet) {
  #root {
    align-items: center;
  }
}

@media (min-width: var.$breakpoint-desktop) {
  html,
  body,
  #root,
  .page-container {
    min-height: 100vh;
  }

  #root {
    justify-content: center;
    gap: 3em;
  }
}
