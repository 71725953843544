// Font Imports
/* space-mono-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  src: url("../media/fonts/space-mono-v12-latin-700.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url("../media/fonts/space-mono-v12-latin-700.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// Colours
$clr-white: #fff;
$clr-grey-light: #f3f9fa;
$clr-grey: #9ebbbd;
$clr-red: #e17457;

$clr-cyan-100: #c5e4e7;
$clr-cyan-200: #9fe8df;
$clr-cyan-300: #7f9d9f;
$clr-cyan-400: #26c2ae;
$clr-cyan-500: #5e7a7d;
$clr-cyan-600: #547878;
$clr-cyan-700: #3d6666;
$clr-cyan-800: #00474b;
$clr-cyan-900: #0d686d;

// Fonts
$font-family-main: "Space Mono", sans-serif;
$font-weight-normal: 700;

// Breakpoints
$breakpoint-tablet: 700px;
$breakpoint-desktop: 1000px;
