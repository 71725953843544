@use "../../scss/variables.scss" as var;

.calculator__result {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;

  &-label {
    display: flex;
    flex-direction: column;
  }

  &-title {
    color: var.$clr-white;
    font-size: 1rem;
  }

  &-subtitle {
    color: var.$clr-cyan-300;
    font-size: 0.8125rem;
  }

  &-value {
    color: var.$clr-cyan-400;
    font-size: 2rem;
  }
}

@media (min-width: var.$breakpoint-desktop) {
  .calculator__result {
    flex-grow: unset;
    align-items: center;
    &-value {
      font-size: 3rem;
    }
  }
}
