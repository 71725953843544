@use "../../scss/variables.scss" as var;

.calculator__input-group {
  flex-direction: column;
  display: flex;
  gap: 2em;
}

@media (min-width: var.$breakpoint-desktop) {
  .calculator__input-group {
    width: 50%;
    padding: 0 0.75em 0 0;
    gap: 2.5em;
    justify-content: space-evenly;
  }
}
